import { getCurrentInstance, ref } from 'vue'
import api from '@/request/api/finance'
import { ElMessage } from 'element-plus'

export default function init() {
  const { proxy } = getCurrentInstance()

  const refreshStatus = async (rechargeId) => {
    const res = await api.setRecharge(rechargeId)
    if (res.code == 200) {
      ElMessage.success('该订单充值成功')
      proxy.$router.push('/recharge_list')
    } else {
      ElMessage.error('该订单充值未成功')
      proxy.$router.push('/recharge')
    }
  }

  const initConfirmData = (type, res) => {
    if (['支付宝', '支付宝1', '微信', '银联'].indexOf(type) >= 0)
      return Object.assign(
        {
          dollar: res.data.dollar,
          handlingFee: res.data.handling_fee,
          amount: res.data.amount,
          totalAmount: res.data.total_amount,
        },
        ['支付宝1', '微信'].indexOf(type) >= 0
          ? {
              payId: res.id,
            }
          : {
              url: res.url,
              rechargeId: res.data.id,
            }
      )

    if (['连连支付', 'PingPong', '万里汇'].indexOf(type) >= 0)
      return {
        url: res.url,
        rechargeId: res.id,
      }

    if (['空中云汇'].indexOf(type) >= 0)
      return {
        dollar: res.airwallex_data.dollar,
        handlingFee: res.airwallex_data.handling_fee,
        totalAmount: res.airwallex_data.total_amount,
        airwallex: res.airwallex,
      }

    if (['Payoneer'].indexOf(type) >= 0)
      return {
        dollar: res.data.dollar,
        handlingFee: res.data.handling_fee,
        totalAmount: res.data.total_amount,
        balance: res.data.balance,
        recharge_no: res.data.recharge_no,
      }
  }

  return {
    refreshStatus,
    initConfirmData,
  }
}
