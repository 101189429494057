<template>
  <!-- 实名认证对话框 -->
  <el-dialog v-model="dialogShow" title="实名认证" width="700px" @close="$emit('close', '')">
    <el-alert title="帮助提示" type="warning" show-icon :closable="false" style="margin-bottom: 20px">
      根据国家外汇局规定，实名认证后才可进行充值交易。请正确填写贵公司人员身份信息和银行卡号，以防银行核查。不会占用个人外汇额度。
    </el-alert>
    <!-- 内容主体区域 -->
    <el-form ref="formRef" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="姓名" prop="name">
        <el-input placeholder="请输入姓名" size="small" v-model.trim="form.name" clearable></el-input>
      </el-form-item>
      <el-form-item label="身份证号" prop="idCard">
        <el-input placeholder="请输入身份证号" size="small" v-model.trim="form.idCard" clearable></el-input>
      </el-form-item>
      <el-form-item label="银行卡号" prop="bankCard">
        <el-input placeholder="请输入银行卡号" size="small" v-model.trim="form.bankCard" clearable></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submit">确 定</el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script setup>
import {ref} from "vue";
import api from "@/request/api/finance";
import init from "@/views/finance/recharge/base";
import {ElMessage} from "element-plus";

const emit = defineEmits(['update'])
const props = defineProps({
  type: {required: true},
  dollar: {required: true},
  isSpecial: {type: Boolean, required: true}
})
const formRef = ref()
const form = ref({
  type: props.type,
  dollar: props.dollar,
  name: '',
  idCard: '',
  bankCard: ''
})

const rules = {
  name: [{required: true, message: '请输入您的真实姓名', trigger: 'blur'}],
  idCard: [
    {required: true, message: '请输入您的身份证号码', trigger: 'blur'},
    {
      pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
      message: '请输入正确格式的身份证号码',
      trigger: 'blur'
    }
  ],
  bankCard: [{required: true, message: '请输入您的银行卡号', trigger: 'blur'}]
}
const dialogShow = ref(true)

const {refreshStatus, initConfirmData} = init()

const submit = async () => {
  const result = await formRef.value.validate()
  if (true !== result) return

  if (props.isSpecial) {
    let res = await api.specialVerify(form.value)
    if (!res || !res.code || res.code != 200) return ElMessage.error('实名认证出错')
    ElMessage.success('实名认证成功')
    emit('update', '')
  } else {
    let res = await api.verify(form.value)
    if (!res || !res.data) return ElMessage.error('实名认证出错')
    ElMessage.success('实名认证成功')
    emit('update', initConfirmData(form.value.type, res))
  }

  dialogShow.value = false
}
</script>

<style scoped>

</style>